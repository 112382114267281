import React from "react";
import "./HeadingCAT.css";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

/**
 * Header Component
 * This component Serves as a call to action which let's a visitor know which of the highlight policy they can select
 * @returns a JSX element containing a header, text and a button
 */

function Heading() {
  return (
    <div className="HeadingCAT">
      <div className="HeadingCAT_Right">
        <div>
          <h1>Wonder what you need ?</h1>
          <p>Which type of insurance matches your needs?</p>
        </div>
        <div className="learnMoreButton">
          <Link to="/products">
            <Button variant="contained" size="large" color="green">
              Learn More
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Heading;
