import React from 'react';
import GetInTouch from '../components/GetInTouch'
import MainContactInfo from '../components/MainContactInfo';
import OtherBranches from '../components/OtherBranches';
import './contact.css';

/** 
 * Contact Page
 * This page contains all the contact information about Prime Gambia
 * @returns a JSX element containing components
 */

function Contact() {
    return (
        <div style={{width: '100%'}}>
            <div className='Contact_Us_Highlight'>
                <div>
                    <h1>Contact Us</h1>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur <br /> adipiscing  elit, sed do eiusmod tempor <br /> incididunt ut labore et dolore magna aliqua.</p> */}
                </div>
            </div>
            <MainContactInfo />
            <GetInTouch />
            <OtherBranches />
        </div>
    )
}

export default Contact;