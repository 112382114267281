import React from 'react';
import './PersonalDetails.css';
import FormsHeading from './FormsHeading';

function PersonalDetails() {
  return (
        <div className='PersonalDetails'>
            <div>
                <FormsHeading text='Personal  Details' />
            </div>
            <div className='PersonalDetails_Forms'> 
                <form action='#'> 
                    <div className='PersonalDetails_TopQuery'>
                        <div className='PersonalDetails_InputBox_Query'>
                            <label for="fname">Fullname: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="text" placeholder="Please enter your fullname" required />
                        </div>
                        <div className='PersonalDetails_InputBox_Query'>
                            <label for="fname">Phone: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="tel" placeholder="Enter your contact here" required />
                        </div>
                        <div className='PersonalDetails_InputBox_Query'>
                            <label for="fname">Email Address: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="email" placeholder="Enter your email number" required />
                        </div>
                    </div>
                    <FormsHeading text='Policy  Details' />
                    <div className='PersonalDetails_MiddleQuery'>
                        <div className='PersonalDetails_InputBox_Query'>
                            <label for="fname">Effective Date: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="date" placeholder="Enter Commencement Date" required />
                        </div>
                        <div className='PersonalDetails_InputBox_Query'>
                            <label for="fname">Expiry Date: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="date" placeholder="Enter Expiration Date" required />
                        </div>
                        <div className='PersonalDetails_InputBox_Query'>
                            <label for="fname">Cover Type: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="text" placeholder="Select Cover Type" required name="example" list="exampleCover"/>
                                <datalist id="exampleCover" style={{backgroundColor: 'green'}}>
                                    <option value="Comprehensive" />  
                                    <option value="Third-Party" />
                                    <option value="Third-Party Fire and Theft" /> 
                                    <option value="None" /> 
                                </datalist>
                        </div>
                        <div className='PersonalDetails_InputBox_Query'>
                            <label for="fname">Currency: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="text" placeholder="Select Currency" required name="example" list="exampleList"/>
                                <datalist id="exampleList" style={{backgroundColor: 'green'}}>
                                    <option value="GMD" />  
                                    <option value="USD" />
                                    <option value="GHC" /> 
                                    <option value="GBP" /> 
                                </datalist>
                        </div>
                    </div>
                    <div className='PersonalDetails_TopQuery'>
                        <div className='PersonalDetails_InputBox_Query'>
                            <label for="fname">Building: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="text" placeholder="Enter value of building" required />
                        </div>
                        <div className='PersonalDetails_InputBox_Query'>
                            <label for="fname">Content: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="email" placeholder="Enter value of content" required />
                        </div>
                        <div className='PersonalDetails_InputBox_Query'>
                            <label for="fname">Public Liability: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="tel" placeholder="Enter public liability amount" required />
                        </div>
                        <div className='PersonalDetails_InputBox_Query'>
                            <label for="fname">Rent: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="text" placeholder="Enter rent value" required />
                        </div>
                        <div className='PersonalDetails_InputBox_Query'>
                            <label for="fname">Personal Accident: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="email" placeholder="Enter personal accident value" required />
                        </div>
                        <div className='PersonalDetails_InputBox_Query'>
                            <label for="fname">Total Sum Insured: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="tel" placeholder="0" required />
                        </div>
                    </div>
                    <div className="submitAction" >
                        <input className='FormSubmit' type="submit" />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default PersonalDetails;
