import React from 'react';
import Button from '@mui/material/Button';
import CarInsurance from '../media/img/Products - highlight/MotorInsurance.jpg';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import './ProductItem.css';
import {Link} from "react-router-dom";

/** 
 * Products Item Component
 * This component displays a single insurance policy on the homepage
 * @param props.text - this text item is there to highlight each product
 * @returns a JSX element that displays an image along side its title
 */

function ProductItem({Title, text, Icon, Image, link}) {
    return (
        <div className='Products_Section_Card' style={{backgroundImage: `url(${Image})`}}>
            <span>
                <div className='ProtuctIcon'>
                    {Icon}
                </div>
                <div className='ProductContent'>
                    <h2>{Title}</h2>
                    <p className='text-ellipsis'>
                        {text}
                    </p>
                </div>
                <div>
                    <Button variant="outlined" style={{backgroundColor: '#fff', color: '#185C2E', border: '#fff', fontWeight: '700'}}>{link}</Button>
                </div>
            </span>
        </div>
    )
}

export default ProductItem