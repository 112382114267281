import React from 'react';
import './FormsDownloadLink.css';
import PDF from '../media/img/pdf.png';

function FormsDownloadLink({text}) {
  return (
    <div className='FormsDonwloadLink'>
        <div>
            <h2>{text}</h2>
            <img src={PDF} style={{height: '35px', marginTop: '6px'}}/>
        </div>
    </div>
    )
}

export default FormsDownloadLink;
