import React from 'react';
import './MissionAndVision.css';

function MissionAndVision() {
  return (
        <div className='MissionAndVision'>
            <div> 
                {/* Upper Section of the Partners page */}   
                <div className='MissionAndVision_top'>
                    <h1>ABOUT US</h1>
                    <p>Prime Insurance is a one-stop <br/> financial institution in General <br/> Insurance business.</p>
                </div>

                {/* Lower Section of the Footer page */}   
                <div className='MissionAndVision_down'>
                    <div className='MissionAndVision_down_Vision'>
                        <h2>Vision</h2>
                        <p>
                            To be a role model for Gambian Businesses through 
                            the consistent achievement of superior returns for 
                            our numerous stakeholders
                        </p>
                    </div>
                    <div className='MissionAndVision_down_Mission'>
                    <h2>Mission</h2>
                        <p>
                        To build an efficient insurance institution anchored on professionalism with commitment to integrity, empathy, prudence and an uncompromising quality in service delivery.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MissionAndVision;
