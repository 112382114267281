import React from "react";
import "../App.css";
import Footer from "../components/Footer";
import Partners from "../components/Partners";
import FirstChoice from "../components/FirstChoice";
import FreeQuote from "../components/FreeQuote";
import HeadingCAT from "../components/HeadingCAT";
import ProductSection from "../components/ProductSection";
import Header from "../components/Header";
import Bestprovider from "../components/Bestprovider";
import Navbar from "../components/Navbar/Navbar";
import QuotePolicies from "../components/QuotePolicies";
import QuotePoliciesAction from "../components/QuotePoliciesAction";
import FaqNAbout from "../components/FaqNAbout";
import Anniversary from "../components/Anniversary";

/**
 * Index page
 * This page is purposefly for downloading all forms data
 * @returns a JSX components that makes up the homepage
 */

function Home() {
  return (
    <div className="Home">
      {/* Nav *
      <Navbar /> */}
      {/* Header */}
      <Header />
      {/* First Choice Caption */}
      <FirstChoice Title="FIRST CHOICE" />
      {/* Anniversary */}
      <Anniversary />
      {/* Heading */}
      <HeadingCAT />
      {/* Highlighted Products */}
      <ProductSection />
      {/* ssssssss */}
      {/* <FreeQuote className="doNotShowOnMobile" /> */}
      <FirstChoice Title="GET A QUOTE" />
      {/* Request a free quote */}
      <QuotePolicies />
      {/* Request a free quote */}{" "}
      {/*
      <QuotePoliciesAction /> */}
      {/* First Choice Caption */}
      {/* <FirstChoice Title="BEST PROVIDER" style={{margin: '100px 0px 0px 0px'}} />  */}
      <Bestprovider />
      {/* Partners */}
      <Partners />
      {/* <FaqNAbout /> */}
      {/* Footer *
      <Footer /> */}
    </div>
  );
}

export default Home;
