import React from "react";
import "./OtherBranchesItem.css";

/**
 * OtherBranchesItem Component
 * This component is basically for displaying each contact item inside the OtherBranches component
 * @param props.title - the name of each branch
 * @param props.text - the location of each branch
 * @param props.text1 - the phone number for each branch
 * @returns a JSX element for branches
 */

function OtherBranchesItem({ text, text1, title }) {
  return (
    <div className="otherBranchItem">
      <div className="OtherBranchesItem_Branch">
        <h3>{title}</h3>
      </div>
      <div className="OtherBranchesItem_Tel">
        <p>{text}</p>
        <p>{text1}</p>
      </div>
    </div>
  );
}

export default OtherBranchesItem;
