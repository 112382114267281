import React from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

function HomeownerInsuranceBenefits() {
  return (
    <div>
                    <div className='productsPageExplanation'>
                <h1>What does Fire &amp; Allied Perils insurance cover?</h1>
                <p>
                 This insurance policy covers all accidents that affects a building and its contents against Several Perils, and  structures against Other Unforeseen Incidents.
                
                Choose the right insurance package for your home. We offer coverage on all enlisted points below. No matter your choice, we've got you covered.
                </p>
                <div className='productsPageBenefitsList'>
                    <div>
                        <CheckBoxIcon small="medium" style={{color: '#175C2E', marginRight: '15px'}} />
                        <p>
                            Fire
                        </p>
                    </div>
                    <div>
                        <CheckBoxIcon small="medium" style={{color: '#175C2E', marginRight: '15px'}} />
                        <p>
                            Lightening
                        </p>
                    </div>
                    <div>
                        <CheckBoxIcon small="medium" style={{color: '#175C2E', marginRight: '15px'}} />
                        <p>
                            Limited explosion
                        </p>
                    </div>
                    <div>
                        <CheckBoxIcon small="medium" style={{color: '#175C2E', marginRight: '15px'}} />
                        <p>
                            Civil Commotion
                        </p>
                    </div>
                    <div>
                        <CheckBoxIcon small="medium" style={{color: '#175C2E', marginRight: '15px'}} />
                        <p>
                            Malicious Damage
                        </p>
                    </div>
                    <div>
                        <CheckBoxIcon small="medium" style={{color: '#175C2E', marginRight: '15px'}} />
                        <p>
                            Leakage from automatic sprinkler system
                        </p>
                    </div>
                    <div>
                        <CheckBoxIcon small="medium" style={{color: '#175C2E', marginRight: '15px'}} />
                        <p>
                            Subsistence, Landslide and Rock slide
                        </p>
                    </div>
                    <div>
                        <CheckBoxIcon small="medium" style={{color: '#175C2E', marginRight: '15px'}} />
                        <p>
                            Bursting of water pipes
                        </p>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default HomeownerInsuranceBenefits