import React from 'react';
import './AboutContent.css'
import AboutUsImage from '../media/img/aboutUsPic2.png';

function AboutContent() {
  return (
    <div className='AboutContent'>
        <div>
            <div className='AboutContent_Image'>
                <img src={AboutUsImage} />
            </div>
            <div className='AboutContent_Text'>
                <h1>About Prime Insurance</h1>
                <p>Prime Insurance Company Limited, “The Company”, was incorporated on the 14th of February 
                    1997. The company was licensed on the 26th of May 1997 and commenced business on the 1st 
                    of July 1997 to carry on non-life insurance business.
                    <br/> <br />                
                    The Company’s strategic focus, as a comprehensive one-stop financial institution in 
                    General Insurance business, has enable it to achieve a balanced and sustained growth, 
                    borne largely out of the ability to win and retain market share through innovative, 
                    courteous and efficient service delivery.

                </p>
            </div>
        </div>
    </div>
    )
}

export default AboutContent;
