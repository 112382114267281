import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./Anniversary.css";
import { Button } from "@mui/material";
import AnniversaryMedal from "../media/img/awardMedal.png";
import { Link } from "react-router-dom";
import AnniversaryImg1 from "../media/img/award1.jpg";

function Anniversary() {
  return (
    <div className="anniversary">
      <div className="anniversaryContainer">
        <div className="anniversaryContainerLeft">
          <h1>We are celebrating 25 years of success</h1>
          <p style={{ margin: "20px 0px" }}>
            Our business have come a long way. There are numerous areas in which
            our insurance policies have benefited our clients all over Gambia.
            We have become the premiere hub for all insurance coverages.
          </p>
          <Link to="/about">
            <Button
              variant="contained"
              style={{
                width: "150px",
                backgroundColor: "#11823B",
                textDecoration: "none",
                color: "#fff",
              }}
            >
              Read More
            </Button>
          </Link>
        </div>
        <div className="anniversaryContainerRight">
          <div className="anniversaryContainerRightTrophy">
            <img src={AnniversaryMedal} height="250" />
          </div>
          <div className="anniversaryContainerRightCarousel">
            <span>
              <h1 style={{ fontSize: "50px" }}>25</h1>
              <h1 className="reduceFont">YEARS</h1>
              <h1 className="reduceFont">ANNIVERSARY</h1>
            </span>
            {/* <OwlCarousel className='owl-theme' loop margin={10} nav style={{width: '400px'}}>
                        <div class='item' style={{height: '200px', width: '320px'}}>
                            <img src={AnniversaryImg1} style={{height: '200px', width: '320px'}} />
                        </div>
                        <div class='item'>
                            <img src={AnniversaryImg1} style={{height: '250px', width: '320px'}} />
                        </div>
                        <div class='item'>
                            <img src={AnniversaryImg1} style={{height: '250px', width: '320px'}} />
                        </div>
                        <div class='item'>
                            <img src={AnniversaryImg1} style={{height: '250px', width: '320px'}} />
                        </div>                    
                    </OwlCarousel>; */}
            {/* <img src={AnniversaryImg1} style={{height: '300px', width: '480px'}} /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Anniversary;
