import React from 'react';
import './GetInTouch.css';
import Support from '../media/img/Ccontact.png';
import GetInTouchForms from './GetInTouchForms';

function GetInTouch() {
  return (
        <div className='GetInTouch'>
            <div className='GetInTouch_Container'>
                <div className='GetInTouch_Container_Forms'>
                    <GetInTouchForms />
                </div>
                <div className='GetInTouch_Container_Illustration'>
                    <img src={Support} />
                </div>
            </div>
        </div>
  )
}

export default GetInTouch;
