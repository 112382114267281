import React from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

function MotorInsuranceBenefits() {
  return (
    <div>
                    <div className='productsPageExplanation'>
                <h1>What does motor insurance cover?</h1>
                <p>
                 Prime Insurance Gambia offers world-class service, prompt claims settlement and flexible motor insurance policies that are tailored to meet your insurance needs. Let's talk about your car insurance.
                <br/> <br />
                Choose the right insurance package for your car. We offer flexible third party and comprehensive motor insurance policies to meet your needs. No matter your choice, we've got you covered.
                </p>
                <div className='productsPageBenefitsList'>
                    <div>
                        <CheckBoxIcon small="medium" style={{color: '#175C2E', marginRight: '15px'}} />
                        <p>
                            Damage to third party property
                        </p>
                    </div>
                    <div>
                        <CheckBoxIcon small="medium" style={{color: '#175C2E', marginRight: '15px'}} />
                        <p>
                            Personal accident cover
                        </p>
                    </div>
                    <div>
                        <CheckBoxIcon small="medium" style={{color: '#175C2E', marginRight: '15px'}} />
                        <p>
                            Theft to your vehicle
                        </p>
                    </div>
                    <div>
                        <CheckBoxIcon small="medium" style={{color: '#175C2E', marginRight: '15px'}} />
                        <p>
                            Damage to your own car
                        </p>
                    </div>
                    <div>
                        <CheckBoxIcon small="medium" style={{color: '#175C2E', marginRight: '15px'}} />
                        <p>
                            Accidental cost
                        </p>
                    </div>
                    <div>
                        <CheckBoxIcon small="medium" style={{color: '#175C2E', marginRight: '15px'}} />
                        <p>
                            Cover when you travel
                        </p>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default MotorInsuranceBenefits