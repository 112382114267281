import React from 'react';
import './Footer.css'; //CSS file for the Footer component
import LocationOnIcon from '@material-ui/icons/LocationOn'; //Location Icon
import EmailIcon from '@material-ui/icons/Email'; //Email Icon
import LocalPhoneIcon from '@material-ui/icons/LocalPhone'; //Phone Icon
import FacebookIcon from '@material-ui/icons/Facebook'; //FB Icon
import TwitterIcon from '@material-ui/icons/Twitter'; //Twitter Icon
import InstagramIcon from '@material-ui/icons/Instagram'; //Intagram Icon
import LinkedInIcon from '@material-ui/icons/LinkedIn'; //LinkedIn Icon
import SendIcon from '@material-ui/icons/Send'; //Send Icon
import FooterImage from '../media/img/PrimeFooter.png'; //Prime Logo in footer Icon
import {Link} from "react-router-dom";

/** 
 * Footer Component
 * This component is embedded in all pages
 * This section is divided into 2 main blocks. The Upper block contains 4 column and the lower block is a fully occupied block.
 * Frist column : Contains brief Info
 * Second Column : It's dedicated to the navigation items
 * Third Column : It's dedicated to Insurance Policies
 * Fourth Column : It's dedicated to contact info of Prime Gambia
 * @returns a JSX element
 */

function Footer() {
    return (
        <div className='footer'>

            {/* Upper Section of the Footer page */}   
            <div className='footer_Upper_Section'>
                <div> {/* Redundant Div For need of changes in the future */}

                {/* First Column From Left of the Footer page */}     
                <div className='footer_Upper_Section_Col1 First_Responsive_Footer_Top'>
                    <a href=""><img src={ FooterImage } alt="Prime Gambia Logo" width="230" /></a>                    
                    <div>
                        <p>Prime Insurance is a one-stop <br/> financial institution in General <br/> Insurance business.</p>
                        <h4>Latest News & Promo</h4>
                        <div>
                            <input type="email" placeholder='Your email address...'/>
                            <SendIcon />
                        </div>
                        <hr/>
                    </div>
                </div>
                <hr class="visible-phone"/>

                {/* Second Column From Left of the Footer page */} 
                <div className='footer_Upper_Section_Col2 First_Responsive_Footer_Top'>
                    <h3>Explore</h3>
                    <div><Link to="/forms" >Forms</Link></div> 
                    <div><Link to="/contact">Contact Us</Link></div>
                    <div><Link to="/quote">Get A Quote</Link></div>
                    <div><Link to="/products">Our Products</Link></div>
                    <div><Link to="/about">About Us</Link></div>
                </div>
                <hr class="visible-phone"/>
                {/* onClick={() => window.location.reload()} */}
                {/* Third Column From Left of the Footer page */} 
                <div className='footer_Upper_Section_Col3 First_Responsive_Footer_Bottom'>
                    <h3>Our Services</h3>
                    <div><Link to="/homeownerInsurance" >Fire &amp; Allied Perils</Link></div>
                    <div><Link to="/moneyInsurance" >Money Insurance</Link></div>
                    <div><Link to="/travelInsurance" >Travel Insurance</Link></div>
                    <div><Link to="/goodsInTransit" >Goods-In-Transit</Link></div>
                    <div><Link to="/motorInsurance" >Motor Insurance</Link></div>
                </div>
                <hr class="visible-phone"/>

                {/* Fourth Column From Left of the Footer page */} 
                <div className='footer_Upper_Section_Col4 First_Responsive_Footer_Bottom'>
                    <h3>Contact Us</h3>
                    <div>
                        <LocationOnIcon />
                        <p>10c Nelson Mandela Street, <br/> Banjul, The Gambia</p>
                    </div>
                    <div>
                        <EmailIcon />
                        <p>Email: info@primeinsurancegambia.com</p>
                    </div>
                    <div>
                        <LocalPhoneIcon />
                        <p>Phone: +220 422 2476, 422 2302</p>
                    </div>
                    <div className='footer_Upper_Section_Col4_Socials'>
                        <div>
                            <a href=""><FacebookIcon /></a>
                        </div>
                        <div>
                            <a href=""><TwitterIcon /></a>
                        </div>
                        <div>
                            <a href=""><InstagramIcon /></a>
                        </div>
                        <div>
                            <a href=""><LinkedInIcon /></a>
                        </div>
                    </div>
                </div>
            </div>
            </div> 

            {/* Lower Section of the Footer page */}   
            <div className='footer_Lower_Section'>
                <p>©{new Date().getFullYear()} Copyright | All Rights Reserved</p>
            </div>
        </div>
    )
}

export default Footer;
