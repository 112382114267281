import React, { useState } from 'react';
import './QuotePolicies.css';
import QuotePoliciesItem from './QuotePoliciesItem';
import QuotePoliciesAction from './QuotePoliciesAction';
import Car from '../media/img/Car.svg';
import Fire from '../media/img/fire.svg';
import Plane from '../media/img/aeroplane.svg';
import Injury from '../media/img/injury.png';
import GetAQuoteMotor from './GetAQuoteMotor';
import GetAQuoteInjury from './GetAQuoteInjury';
import GetAQuoteTravel from './GetAQuoteTravel';
import PersonalDetails from './PersonalDetails';
import MotorInsuranceBenefits from '../pages/benefits/MotorInsuranceBenefits';
import HomeownerInsuranceBenefits from '../pages/benefits/HomeownerInsuranceBenefits';
import TravelInsuranceBenefits from '../pages/benefits/TravelInsuranceBenefits';

/** 
 * QuotePolicies Component
 * This component is solely for an interactive product Selection
 * The use of React useState called toggleState to select a single product and enlist the necessary actions below it
 * @returns interractive JSX elements that is rendered to give an interractive feel to interracting with each product and returns <QuotePoliciesAction /> components
 */

function QuotePolicies() {

        const [value, setValue] = React.useState('1');
        const handleChange = (event, newValue) => {setValue(newValue);};

        const [toggleState, setToggleState] = useState(0);
        const toggleTab = (index) => {
            setToggleState(index)
        }

    return (
        <div className='QuotePolicies'> 


            {/* Ineractive Policies */}
            <div>
                <div className='QuotePolicies_Cards bloc-tabs'>
                    <div className={toggleState === 1 ? "tabs active-tab hightlight" : "tabs"} onClick={() => toggleTab(1)} style={{cursor : 'pointer'}}>
                        {/* <span className={toggleState === 1 ? "tabs active-tab hightlight" : "tabs"} onClick={() => toggleTab(1)} style={{width: '100%', padding: '8px', backgroundColor: '#96C947'}}> */}
                            <QuotePoliciesItem title="Motor Insurance" text="This covers your vehicles" text1=" from potential risks..." src={Car} />
                        {/* </span> */}
                            {/* { toggleState === 1 && <div className={toggleState === 1 ? "hightlightBar" : "tabs"} onClick={() => toggleTab(1)}>
s
                            </div> } */}
                    </div>
                    <div className={toggleState === 2 ? "tabs active-tab hightlight" : "tabs"} onClick={() => toggleTab(2)} style={{cursor : 'pointer'}}>
                        <QuotePoliciesItem title="Fire &amp; Allied Perils" text="This covers any unforeseen" text1=" and sudden physical loss ..." src={Fire} />
                    </div>
                    <div className={toggleState === 3 ? "tabs active-tab hightlight" : "tabs"} onClick={() => toggleTab(3)} style={{cursor : 'pointer'}}>
                        <QuotePoliciesItem title="Travel Insurance" text="This provides compensation" text1=" for death, injuries..." src={Plane} />
                    </div>
                    <div className={toggleState === 4 ? "tabs active-tab hightlight" : "tabs"} onClick={() => toggleTab(4)} style={{cursor : 'pointer'}}>
                        <QuotePoliciesItem title="Accidents" text="This covers accidental" text1=" injuries  or death and ..." src={Injury} style={{backgroundColor : "#96C947"}} />
                    </div>
                </div>
                <div className='content-tabs'>
                    <div className={toggleState === 1 ? "content active-content" : "content"} style={{backgroundColor : ''}}>
                        <QuotePoliciesAction title="Motor Insurance" content={<GetAQuoteMotor/>} benefits={<MotorInsuranceBenefits />} />
                    </div>
                    <div className={toggleState === 2 ? "content active-content" : "content"} style={{backgroundColor : ''}}>
                        <QuotePoliciesAction title="Fire &amp; Allied Perils" content={<PersonalDetails />} benefits={<HomeownerInsuranceBenefits />} />
                    </div>
                    <div className={toggleState === 3 ? "content active-content" : "content"} style={{backgroundColor : ''}}>
                        <QuotePoliciesAction title="Travel Insurance" content={<GetAQuoteTravel />} benefits={<TravelInsuranceBenefits />} />
                    </div>
                    <div className={toggleState === 4 ? "content active-content" : "content"} style={{backgroundColor : ''}}>
                        <QuotePoliciesAction title="Accidents" content={<GetAQuoteInjury />} benefits={<MotorInsuranceBenefits />} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuotePolicies