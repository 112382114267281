import React from 'react';
import './Partners.css'; //CSS file for the Parnters component
import CBG from '../media/img/partners/CBG1.png'; 
import Ecowas from '../media/img/partners/ecowas.png';
import Forty from '../media/img/partners/40.png';
import WAICA from '../media/img/partners/WAICA.jpg';
import BCard from '../media/img/partners/BrownCard1.png';
import AIO from '../media/img/partners/African-Insurance-Organisation.png';

/** 
 * Partners Component
 * This component showcases the partners of Prime Gambia
 * @returns a JSX element for branches
 */

function Partners() {
    return (
        <div className='Partners'>
            <div> 
                {/* Upper Section of the Partners page */}   
                <div className='Partners_top'>
                    <h1>PARTNERS</h1>
                </div>

                {/* Lower Section of the Footer page */}   
                <div className='Partners_down'>
                    <img src={CBG} width='100px' height='70px'/>
                    <img src={Ecowas} width='100px' height='60px' />
                    <img src={WAICA} width='100px' height='60px' />
                    <img src={BCard} width='100px' height='60px' />
                    <img src={AIO} width='100px' height='60px' />
                    <img src={Forty} width='100px' height='60px' />
                </div>
            </div>
        </div>
    )
}

export default Partners
