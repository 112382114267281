import React from 'react';
import './FormsHeading.css';

function FormsHeading({text}) {
  return (
  <div>
      <div className='Forms_Heading'>
                <div className='Forms_Heading_Thread_Left'>
                    <hr /> {/* Slim bar running accross to the left, which is hidden on desktop view */}
                </div>
                <div className='Forms_Heading_Main'>
                    <h2>{text}</h2> {/* Actual Content for highlight */}
                </div>
                <div className='Forms_Heading_Thread'>
                    <hr /> {/* Slim bar running accross to the right */}
                </div>
            </div>
  </div>
  )
}

export default FormsHeading;
