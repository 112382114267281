import React from 'react';
import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import { Link as LinkR } from 'react-router-dom';
import MobileNavBG from '../../media/img/SVG/headerMobile.svg'

export const SidebarContainer = styled.aside`
    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0d0d0d;
    align-items: center;
    transition: 0.4s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? '100%' : '0%')};
    top: ${({ isOpen }) => (isOpen ? '0%' : '-100%')}


    // position: fixed;
    // z-index: 999;
    // width: 100%;
    // height: 100%;
    // background: #0d0d0d;
    // display: grid;
    // align-items: center;
    // top: 0;
    // left: 0;
    // transition: 0.3s ease-in-out;
    // opacity: ${({ isOpen }) => (isOpen ? '100%' : '0%')};
    // top: ${({ isOpen }) => (isOpen ? '0%' : '-100%')}
`

export const CloseIcon = styled(FaTimes)`
    color: #fff;
    font-size: 40px;
`

export const Icon = styled.div`
    
    //display: flex;
    height: 15%;
    //width: 100%;
    justify-content: right;
    padding: 20px 20px 0px 0px;
    //z-index: 1000;
    cursor: pointer;
    outline: none;
    background: transparent;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;



    // position: abolute;
    // top: 1.2rem;
    // right: 1.5rem;
    // background: transparent;
    // font-size: 2rem;
    // cursor: pointer;
    // outline: none;
`

export const SidebarWrapper = styled.div`
    color: #fff;
    height: 55%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 480px) {
        margin-top: 25%;
    }
`

export const SidebarMenu = styled.ul`
    // display: grid;
    // grid-template-columns: 1fr;
    // grid-template-rows: repeat(6, 80px);
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 10px 0px;

    @media screen and (max-width: 480px) {
        //grid-template-rows: repeat(6, 10px);
        margin: 10px 0px;
    }

    @media screen and (max-width: 380px) {
        //grid-template-rows: repeat(6, 8px);
        margin: 5px 0px;
    }


`;

export const SidebarLink = styled(LinkR)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color: #fff;

    &:hover {
        color: #01bf71;
        transition: 0.2s ease-in-out;
    }
`

export const SideBtnWrap = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;

    @media screen and (max-width: 380px) {
        margin-top: 10px;
    }
`

export const SidebarRoute = styled(LinkR)`
    border-radius: 50px;
    background: #96C947;
    white-space: nowrap;
    padding: 16px 64px;
    color: #010606;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    tex-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #010606;
    }
`

