import { Button } from '@mui/material';
import React from 'react';
import './InsuranceProduct.css';

function InsuranceProduct({ProductName, Paragraph1, Paragraph2, InsuranceProductImage}) {
  return (
    <div className='insuranceProduct'>
        <div className='insuranceProductInfo'>
            <h1>{ProductName}</h1>
            <p>
                {Paragraph1}
                <br /> <br />
                {Paragraph2}
            </p>
            {/* <Button variant='contained' style={{backgroundColor: '#175C2E', color: '#fff'}}>
                Get A Quote
            </Button> */}
        </div>
        <div className='insuranceProductImg'>
            <img src={InsuranceProductImage} />
        </div>
    </div>
  )
}

export default InsuranceProduct