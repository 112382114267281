import React from "react";
import "./MainContactInfo.css";
import MainContactInfoItem from "./MainContactInfoItem";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import EmailIcon from "@material-ui/icons/Email";

/**
 * Main Contact Info Component
 * This component serves as the center piece for the homepage that rotates multiple animations to represent the services of the brand
 * This section is divided into 2 blocks
 * LocalPhoneIcon - This imports a phone icon
 * EmailIcon - This imports an email icon
 * LocationOnIcon - This imports a location icon
 * @returns a JSX element that displays contact info about Prime Gambia
 */

function MainContactInfo() {
  return (
    <div className="MainContactInfo_Section">
      <div>
        {/* Headoffice Phone Number */}
        <MainContactInfoItem
          title="PHONE"
          action="CALL US"
          Icon={LocalPhoneIcon}
          text="Tel: (00220) 4222 476"
          text1=""
          // text1="Fax: (00220) 4222 475"
        />
      </div>
      <div>
        {/* Headoffice Email */}
        <MainContactInfoItem
          title="EMAIL"
          action="SEND EMAIL"
          Icon={EmailIcon}
          text="info@primeinsurancegambia.com"
        />
      </div>
      <div>
        {/* Headoffice Location */}
        <MainContactInfoItem
          title="LOCATION"
          action="HEAD OFFICE"
          Icon={LocationOnIcon}
          text="10c Nelson Mandela Street, Banjul, The Gambia"
        />
      </div>
    </div>
  );
}

export default MainContactInfo;
