import React from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


function TravelInsuranceBenefits() {
  return (
    <div>
                    <div className='productsPageExplanation'>
                <h1>What does travel insurance cover?</h1>
                <p>
                ( In partnership with Reise-Schutz AG of Germany) for visitors to
                Europe including the SCHENGEN States. The policy provides compensation for death,
                injuries, and sickkness whilst away to these countries.
                
                </p>
                <div className='productsPageBenefitsList'>
                    <div>
                        <CheckBoxIcon small="medium" style={{color: '#175C2E', marginRight: '15px'}} />
                        <p>
                            Emergency medical expenses
                        </p>
                    </div>
                    <div>
                        <CheckBoxIcon small="medium" style={{color: '#175C2E', marginRight: '15px'}} />
                        <p>
                            Hospitalization
                        </p>
                    </div>
                    <div>
                        <CheckBoxIcon small="medium" style={{color: '#175C2E', marginRight: '15px'}} />
                        <p>
                            Repatriation of remains
                        </p>
                    </div>
                    <div>
                        <CheckBoxIcon small="medium" style={{color: '#175C2E', marginRight: '15px'}} />
                        <p>
                            Loss of travel documents
                        </p>
                    </div>
                    <div>
                        <CheckBoxIcon small="medium" style={{color: '#175C2E', marginRight: '15px'}} />
                        <p>
                            Flight delay
                        </p>
                    </div>
                    <div>
                        <CheckBoxIcon small="medium" style={{color: '#175C2E', marginRight: '15px'}} />
                        <p>
                            Loss of luggage
                        </p>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default TravelInsuranceBenefits