import React from "react";
import ProductItem from "./ProductItem";
import "./ProductSection.css";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import CarInsurance from "../media/img/Products - highlight/MotorInsurance.jpg";
import FireInsurance from "../media/img/Products - highlight/fireInsurance.png";
import TravelInsurance from "../media/img/Products - highlight/TravelInsurance.jpg";
import GoodsInTransit from "../media/img/Products - highlight/GoodsInTransit.jpg";
import MoneyInsurance from "../media/img/Products - highlight/MoneyInsurance.jpg";
import AviationInsurance from "../media/img/Products - highlight/AviationInsurance.jpg";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import LocalAirportIcon from "@mui/icons-material/LocalAirport";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { Link } from "react-router-dom";
import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";

/**
 * ProductSection Component
 * @returns a JSX element that renders <Products /> component
 */

function ProductSection() {
  return (
    <div className="ProductSection">
      {/* Insurance Policies */}
      <div className="ProductSection_FirstRow">
        <div>
          <ProductItem
            Title="Motor Insurance"
            link={
              <Link to="/motorInsurance" style={{ color: "#175C2E" }}>
                Learn More
              </Link>
            }
            Icon={<DirectionsCarIcon fontSize="large" />}
            text="Motor Insurance Policy is designed to cover accidental damage, fire, theft and injuries. This includes third party liabilities"
            Image={CarInsurance}
          />
        </div>
        <div>
          <ProductItem
            Title="Fire &amp; Allied Perils"
            link={
              <Link to="/homeownerInsurance" style={{ color: "#175C2E" }}>
                Learn More
              </Link>
            }
            Icon={<LocalFireDepartmentIcon fontSize="large" />}
            text="This insurance policy covers all accidents that affects a building and its contents. This inlcudes fire, malicious damage, lightening ..."
            Image={FireInsurance}
          />
        </div>
        <div>
          <ProductItem
            Title="Travel Insurance"
            link={
              <Link to="/travelInsurance" style={{ color: "#175C2E" }}>
                Learn More
              </Link>
            }
            Icon={<LocalAirportIcon fontSize="large" />}
            text="The policy provides compensation for death, injuries, and sickness whilst away."
            Image={TravelInsurance}
          />
        </div>
        <div>
          <ProductItem
            Title="Goods In Transit"
            link={
              <Link to="/goodsInTransit" style={{ color: "#175C2E" }}>
                Learn More
              </Link>
            }
            Icon={<DirectionsBoatIcon fontSize="large" />}
            text="This policy generally provides cover against loss or damage by accidental means to goods. Cover applies whilst the goods are ..."
            Image={GoodsInTransit}
          />
        </div>
        <div>
          <ProductItem
            Title="Money Insurance"
            link={
              <Link to="/moneyInsurance" style={{ color: "#175C2E" }}>
                Learn More
              </Link>
            }
            Icon={<MonetizationOnIcon fontSize="large" />}
            text="The policy covers loss, destruction or damage by any accident or misfortune to money whilst in transit in the circumstances..."
            Image={MoneyInsurance}
          />
        </div>
        <div>
          <ProductItem
            Title="Machinery Breakdown"
            link={
              <Link to="/machineryBreakdown" style={{ color: "#175C2E" }}>
                Learn More
              </Link>
            }
            Icon={<PrecisionManufacturingIcon fontSize="large" />}
            text="This policy covers your machines against any unforeseen and sudden physical loss or damage from causes such as defects in casting..."
            Image={AviationInsurance}
          />
        </div>
        {/* <div className='ProductSection_FirstRow_Card1' style={{backgroundImage: `url(${TravelInsurance})`}}>
                        <a href=''>
                            <ProductItem text='Travel Insurance' />
                        </a>
                    </div> */}
      </div>
    </div>
  );
}

export default ProductSection;
