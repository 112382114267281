import React from 'react';
import './FaqNAbout.css';

function FaqNAbout() {
  return (
    <div className='faqNAbout'>
        <div className='faqNAboutContainer'>
            <div className='aboutUsContainer'>
                About Us
            </div>
            <div className='faqContainer'>
                FAQ
            </div>
        </div>
    </div>
  )
}

export default FaqNAbout