import styled from "styled-components";
import { NavLink as Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';

// import { Link as LinkR } from 'react-router-dom';
// import { Link as LinkS } from 'react-scroll';
// import { FaBars } from 'react-icons/fa';

/** 
 * NavBar Elements
 * This is simply the styled components for all the properties of the navbar
 * @returns a styled item for each component
 */


// export const Nav = styled.nav`
//     background: #175C2E;
//     height: 80px;
//     display: flex;
//     padding: 0px 10%;
//     justify-content: space-between;
//     z-index: 10;
// `

// export const Nav = styled.div`
//     background: #000;
//     height: 80px;
//     margin-top: -80px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     font-size: 1rem;
//     position: sticky;
//     top: 0;
//     z-index: 10;

//     @media screen and (max-width: 960px) {
//         transition: 0.8s all ease;
//     }
// `;

// export const NavbarContainer = styled.div`
//     display: flex;
//     justify-content: space-between;
//     height: 80px;
//     z-index: 1;
//     width: 100%;
//     padding: 0 24px;
//     max-width: 1100px;
// `;

// export const NavLogo = styled(LinkR)`
//     color: #fff;
//     justify-self: flex-start;
//     cursor: pointer;
//     font-size: 1.5rem;
//     display: flex;
//     align-items: center;
//     margin-left: 24px;
//     font-weight: bold;
//     text-decoration: none;
// `;

// export const Bars = styled(FaBars)`
//     display: none;
//     color: #fff;

//     @media screen and (max-width: 768px) {
//         display: block;
//         position: absolute;
//         top: 0;
//         right: 0;
//         transform: translate(-100%, 75%);
//         font-size: 1.8rem;
//         cursor: pointer;
//     }
// `;

// export const NavMenu = styled.ul`
//     display: flex;
//     align-items: center;
//     list-style: none;
//     text-align: center;
//     margin-right: -22px;

//     @media screen and (max-width: 768px) {
//          display: none;
//     }
// `

// export const NavItem = styled.li`
//     height: 80px;
// `

// export const NavLinks = styled(LinkR)`
//     color: #fff;
//     display: flex;
//     align-items: center;
//     text-decoration: none;
//     padding: 0 1rem;
//     height: 100%;
//     cursor: pointer;

//     &.active {
//         border-bottom: 3px solid #01bf71;
//     }
// `

// export const NavBtn = styled.nav`
//     display: flex;
//     align-items: center; 
//     margin-right: 24px;
 
//     @media screen and (max-width: 768px) {
//         display: none;
//     }
// `

// export const NavBtnLink = styled(LinkR)`
//     border-radius: 4px;
//     background: none;
//     padding: 8px 18px;
//     color: #fff; 
//     border: 2px solid #fff;
//     outline: none;
//     cursor: pointer;
//     transition: all 0.2s ease-in-out;
//     text-decoration: none;

//     &:hover {
//         transition: all 0.2s ease-in-out;
//         background: #fff;
//         color: #010606;
//     }
//     @media screen and (max-width: 768px) {
//         display: none;
//     }
// `;
















export const Nav = styled.nav`
    background: #175C2E;
    height: 80px;
    display: flex;
    padding: 0px 10%;
    justify-content: space-between;
    z-index: 10;
`
export const NavLogo = styled(Link)`
    color: #fff;
    display: flex;
    justify-self: flex-start;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;

    // &.active {
    //     color: #96C947;
    // }

    @media screen and (max-width: 768px) {
        color: #fff;
        display: flex;
        align-items: center;
        text-decoration: none;
        padding: 0 1rem;
        height: 100%;
        cursor: pointer;
        z-index: 1997;
        margin-right: 50px;

        // &.active {
        //     color: #96C947;
        // }
    }
`

// export const MobileIcon = styled.div`
// 	display: none;

// 	@medi screen and (max-width: 768px) {
//         display: block;
//         position: absolute;
//         top: 0;
//         right: 0;
//         transform: translate(-100%, 60%);
//         font-size: 1.8rem;
//         cursor: pointer;
//         color: #fff;
// 	}
// `

export const NavLink = styled(Link)`
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;

    &.active {
        color: #96C947;
    }
`

export const Bars = styled(FaBars)`
    display: none;
    color: #fff;

    @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 75%);
        font-size: 1.8rem;
        cursor: pointer;
        z-index: 1999;
    }
`

export const NavMenu = styled.div`
    display: flex;
    align-items: center;
    margin-right: -24px;

    @media screen and (max-width: 768px) {
        display: none;
    }
`

export const NavBtn = styled.nav`
    display: flex;
    align-items: center; 
    margin-right: 24px;
 
    @media screen and (max-width: 768px) {
        display: none;
    }
`

export const NavBtnLink = styled(Link)`
    border-radius: 4px;
    background: none;
    padding: 8px 18px;
    color: #fff; 
    border: 2px solid #fff;
    outline: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #010606;
    }
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

