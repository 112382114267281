/*
Navigaion router to move between pages.
*/


import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as BrowserRouter, Routes, Route, Link, HashRouter } from 'react-router-dom';
import Home from './pages/index';
import Navbar from './components/Navbar/Navbar';
import About from './pages/about';
import Products from './pages/products';
import Contact from './pages/contact';
import Forms from './pages/forms';
import Quote from './pages/quote';
import ScrollToTop from './components/ScrollToTop';
import MotorInsurance from './pages/products/MotorInsurance'
import Footer from './components/Footer';
import HomeownerInsurance from './pages/products/HomeownerInsurance';
import TravelInsurance from './pages/products/TravelInsurance';
import GoodsInTransit from './pages/products/GoodsInTransit';
import MoneyInsurance from './pages/products/MoneyInsurance';
import MachineryBreakdown from './pages/products/MachineryBreakdown';
import Sidebar from './components/Sidebar/Sidebar';

function App() {

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <HashRouter>   

      <ScrollToTop />

      {/* <Helmet>
        <title>Prime Insurance Gambia</title>
      </Helmet> */}

      

      {/* Nav */}
      <Navbar toggle={toggle}/>
      <Sidebar isOpen={isOpen} toggle={toggle} />   
      <Routes> 
        <Route path="/" element={<Home />} /> 
        <Route path="/about" element={<About />} />
        <Route path="/products" element={<Products />} />
        <Route path="/forms" element={<Forms />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/quote" element={<Quote />} />
        <Route path="/motorInsurance" element={<MotorInsurance />} />
        <Route path="/homeownerInsurance" element={<HomeownerInsurance />} />
        <Route path="/travelInsurance" element={<TravelInsurance />} />
        <Route path="/goodsInTransit" element={<GoodsInTransit />} />
        <Route path="/moneyInsurance" element={<MoneyInsurance />} />
        <Route path="/machineryBreakdown" element={<MachineryBreakdown />} />
      </Routes>
      <Footer />
    </HashRouter>
  );
}

export default App;
