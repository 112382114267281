import React from 'react';
import './Bestprovider.css';
import Lottie from 'react-lottie';
import BestproviderItem from './BestproviderItem';
import animationData from '../media/img/passcode.json';
import animationDataa from '../media/img/mail.json';
import animationDataaa from '../media/img/analytics.json';
import animationDataaaa from '../media/img/shield1.json';

function Bestprovider() {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData, animationDataa,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const defaultOptionss = {
    loop: true,
    autoplay: true,
    animationData: animationDataa,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const defaultOptionsss = {
    loop: true,
    autoplay: true,
    animationData: animationDataaa,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const defaultOptionssss = {
    loop: true,
    autoplay: true,
    animationData: animationDataaaa,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div className='bestProvider'>
        <div className='bestProviderHeading'>
          <h1 style={{marginBottom: '20px'}}>Stay insured with Prime Insurance <span style={{color: '#11823B'}}>Gambia</span></h1>
          <p>Take advantage of our insurance policies designed for the maximum benefit of our cherished clients</p>
        </div>
        <div className='bestProviderColumns'>
            <BestproviderItem 
                heading="Easy onboarding"
                text="Prospective customers can receive a quote within minutes without needing to talk to an insurance agent."
                animationIcon={<Lottie 
                options={defaultOptions}
                height={60}
                width={60}
            />} />
            <BestproviderItem
                heading="Fully electronic" 
                text="We have leverage technology in order to help us be right next to our client, within reach of a tap."
                animationIcon={<Lottie 
                options={defaultOptionss}
                height={50}
                width={50}
            />} />
            <BestproviderItem 
                heading="Superior benefits"
                text="We stand out for ensuring easy and quick access of claims, upon intiation."
                animationIcon={<Lottie 
                options={defaultOptionsss}
                height={50}
                width={50}
            />} />
            <BestproviderItem 
                heading="Full protection"
                text="We have policies in place to help our clients with challenges with premium payments."
                animationIcon={<Lottie 
                options={defaultOptionssss}
                height={40}
                width={40}
            />} />
            
        </div>
    </div>
  )
}

export default Bestprovider