import React, { useEffect, useRef } from "react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import "./Header.css";
import lottie from "lottie-web";
import { Button } from "@material-ui/core";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import Slide1 from "../media/img/PrimeCar1.json";
import Slide3 from "../media/img/fireInsurance4.png";
import Slide2 from "../media/img/travelInsurance.png";

/**
 * Header Component
 * This component serves as the center piece for the homepage that rotates multiple animations to represent the services of the brand
 * This section is divided into 2 blocks
 * The container variable uses the useEffect() hook to help rotate all 3 image carousel items
 * @returns a JSX element that services as a carousel and a place to highlight the core values of Prime Gambia
 */

function Header() {
  {
    /*Animation Component for each Policy highlighted on the homepage*/
  }

  const container = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: require("../media/img/PrimeCar1.json"),
    });
  }, []);

  return (
    <div className="Header_Mai">
      <div className="Header">
        <div className="Header_Section">
          {/*Left Section of the Headear Part*/}
          <div className="Header_Caption">
            <h1>REST ASSURED WITH PRIME INSURANCE</h1>
            <p>Secure - Reliable - Efficient</p>{" "}
            {/*}
                    <Button variant="contained" size="large" color="green" >Get Started</Button> */}
          </div>

          {/* Right Section of the Header Part */}
          <div className="Header_Animation">
            <div>
              <Swiper
                //
                modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={50}
                slidesPerView={1}
                navigation={false}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                pagination={{ clickable: true }}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
              >
                <SwiperSlide
                  className="container img1"
                  ref={container}
                ></SwiperSlide>
                <SwiperSlide>
                  <img className="img2" src={Slide2} width="500" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="img3" src={Slide3} width="550" />
                </SwiperSlide>
              </Swiper>
            </div>{" "}
            {/*
                    <div  className='container' ref={container}>
                        
                    </div>   */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
