import React from 'react';
import './GetAQuoteMotor.css';
import FormsHeading from './FormsHeading';

function GetAQuoteMotor() {
  return (
        <div className='GetAQuoteMotor'>
            <div>
                <FormsHeading text='Personal  Details' />
            </div>
            <div className='GetAQuoteMotor_Forms'> 
                <form action='#'> 
                    <div className='GetAQuoteMotor_TopQuery_Tri'>
                        <div className='GetAQuoteMotor_InputBox_Query'>
                            <label for="fname">Fullname: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="text" placeholder="Please enter your fullname" required />
                        </div>
                        <div className='GetAQuoteMotor_InputBox_Query'>
                            <label for="fname">Phone: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="tel" placeholder="Enter your contact here" required />
                        </div>
                        <div className='GetAQuoteMotor_InputBox_Query'>
                            <label for="fname">Email Address: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="email" placeholder="Enter your email number" required />
                        </div>
                    </div>
                    <FormsHeading text='Policy  Details' />
                    <div className='GetAQuoteMotor_TopQuery_Tri'>
                        <div className='GetAQuoteMotor_InputBox_Query'>
                            <label for="fname">Policy type: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="text" placeholder="Select policy type" required name="example8" list="exampleCover8"/>
                                <datalist id="exampleCover8" style={{backgroundColor: 'green'}}>
                                    <option value="Comprehensive" />  
                                    <option value="Third-Party" />
                                    <option value="Theft" />
                                </datalist>
                        </div>
                        <div className='GetAQuoteMotor_InputBox_Query'>
                            <label for="fname">Currency: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="text" placeholder="Select preferred currency" required name="example9" list="exampleCover9"/>
                                <datalist id="exampleCover9" style={{backgroundColor: 'green'}}>
                                    <option value="GMB" />  
                                    <option value="USD" />
                                    <option value="GHC" />
                                </datalist>
                        </div>
                        <div className='GetAQuoteMotor_InputBox_Query'>
                            <label for="fname">Vehicle usage: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="text" placeholder="Vehicle is used for ?" required name="example10" list="exampleCover10"/>
                                <datalist id="exampleCover10" style={{backgroundColor: 'green'}}>
                                    <option value="Commercial" />  
                                    <option value="Non-commercial" />
                                    <option value="2020x" />
                                </datalist>
                        </div>
                    </div>
                    <div className='GetAQuoteMotor_TopQuery'>
                        <div className='GetAQuoteMotor_InputBox_Query'>
                            <label for="fname">Cubic Capacity (CC): <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="text" placeholder="Select Capacity" required name="example6" list="exampleCover6"/>
                                <datalist id="exampleCover6" style={{backgroundColor: 'green'}}>
                                    <option value="Less than or Equal to 1600" />  
                                    <option value="Between 1601 and 2000" />
                                    <option value="Above 2000" />
                                </datalist>
                        </div>
                        <div className='GetAQuoteMotor_InputBox_Query'>
                            <label for="fname">Seating Capacity: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="email" placeholder="Enter your number of seats" required />
                        </div>
                        <div className='GetAQuoteMotor_InputBox_Query'>
                            <label for="fname">Vehicle Registration: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="tel" placeholder="Enter your vehicle number" required />
                        </div>
                        <div className='GetAQuoteMotor_InputBox_Query'>
                            <label for="fname">Year of Manufacture: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="text" placeholder="Select year of make" required name="example7" list="exampleCover7"/>
                                <datalist id="exampleCover7" style={{backgroundColor: 'green'}}>
                                    <option value="2022" />  
                                    <option value="2021" />
                                    <option value="2020x" />
                                </datalist>
                        </div>
                    </div>
                    <div className='GetAQuoteMotor_MiddleQuery'>
                        <div className='GetAQuoteMotor_InputBox_Query'>
                            <label for="fname">Start Date: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="date" placeholder="Enter Commencement Date" required />
                        </div>
                        <div className='GetAQuoteMotor_InputBox_Query'>
                            <label for="fname">End Date: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="date" placeholder="Enter Expiration Date" required />
                        </div>
                    </div>
                    <div className='GetAQuoteMotor_TopQuery'>
                        <div className='GetAQuoteMotor_InputBox_Query'>
                            <label for="fname">Vehicle Value: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="text" placeholder="Enter value of car" required />
                        </div>
                        <div className='GetAQuoteMotor_InputBox_Query'>
                            <label for="fname">Standard TPPD Limit is 2000: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="text" placeholder="Select" required name="example5" list="exampleCover5"/>
                                <datalist id="exampleCover5" style={{backgroundColor: 'green'}}>
                                    <option value="Yes" />  
                                    <option value="No" />
                                </datalist>
                        </div>
                        <div className='GetAQuoteMotor_InputBox_Query'>
                            <label for="fname">Buy Extra TPPD Limit: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="tel" placeholder="Extra Tppd Limit amount" required disabled />
                        </div>
                        <div className='GetAQuoteMotor_InputBox_Query'>
                            <label for="fname">Buy Back Excess?: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="text" placeholder="Select" required name="example5" list="exampleCover5"/>
                                <datalist id="exampleCover5" style={{backgroundColor: 'green'}}>
                                    <option value="Yes" />  
                                    <option value="No" />
                                </datalist>
                        </div>
                    </div>
                    <div className="submitAction" >
                        <input className='FormSubmit' type="submit" />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default GetAQuoteMotor;
