import React from 'react';
import './BestproviderItem.css';
import animationData from '../media/img/try.json';
import animationDataa from '../media/img/try.json';

function BestproviderItem({animationIcon, heading, text}) {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData, animationDataa,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    //   , backgroundColor: '#11823B'
  return (
    <div className='bestProviderItem'>
        <div style={{display: 'flex', alignItems: 'center', paddingLeft: '16px', height: '80px', borderLeft: '2px dashed #d5d5d5'}}>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '60px', height: '60px'}}>
                {animationIcon}
            </div>
        </div>
        <div style={{display: 'flex', alignItems: 'center', paddingLeft: '16px', height: '40px', borderLeft: '2px solid #11823B'}}>
            <h2>{heading}</h2>
        </div>
        <div style={{display: 'flex', alignItems: 'center', paddingLeft: '16px', height: '80px', borderLeft: '2px dashed #d5d5d5'}}>
            <p>
                {text}
            </p>
        </div>
    </div>
  )
}

export default BestproviderItem