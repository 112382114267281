import React from "react";
import "./about.css";
import Partners from "../components/Partners";
import AboutContent from "../components/AboutContent";
import MissionAndVision from "../components/MissionAndVision";
import OtherBranches from "../components/OtherBranches";
import OtherBranchesItem from "../components/OtherBranchesItem";
import Management from "../components/Management";
import ChooseUs from "../components/ChooseUs";

/**
 * About Us Page
 * This page contains all the info about Prime Gambia
 * @returns a JSX element containing <Partners /> component
 */

function About() {
  return (
    <div>
      <MissionAndVision />
      <AboutContent />
      <ChooseUs />
      <div className="About_Branches">
        <h1>Our Network</h1>
        {/* Components for each other branch */}
        <div>
          <div>
            <OtherBranchesItem
              text="Serrekunda, 8 Sayerr Jobe Avenue"
              text1="Tel: (00220) 4377 744/3813 884"
              title="SERREKUNDA BRANCH"
            />
          </div>
          <div>
            <OtherBranchesItem
              text="BRIKAMA, ON GUNJUR HIGHWAY"
              text1="TEL:2205 154 701/9090 288"
              title="BRIKAMA BRANCH"
            />
          </div>
          <div>
            <OtherBranchesItem
              text="Barra, Behind Barra Police Station"
              text1="Tel: (00220) 3813 880/3813 879"
              title="BARRA BRANCH"
            />
          </div>
          <div>
            <OtherBranchesItem
              text="FARAFENNI, ON THE TRANSGAMBIA HIGHWAY"
              text1="TEL: 2205 154 706/25 876 866"
              title="FARAFENNI  BRANCH"
            />
          </div>
          <div>
            <OtherBranchesItem
              text="Soma, Trans Gambia Highway"
              text1="Tel: (00220) 3813874/6307 040"
              title="SOMA BRANCH"
            />
          </div>
          <div>
            <OtherBranchesItem
              text="BRIKAMABA, OPPOSITE JAH OIL"
              text1="TEL:2203 586 032/2685 499"
              title="BRIKAMABA BRANCH"
            />
          </div>
          <div>
            <OtherBranchesItem
              text="Basse, Santa Su"
              text1="Tel: (00220) 3813 882/3813 876"
              title="BASSE BRANCH"
            />
          </div>
          <div></div>
        </div>
      </div>
      <Management />
    </div>
  );
}

export default About;
