import React from "react";
import "./GetInTouchForms.css";

function GetInTouchForms() {
  return (
    <div className="GetInTouchForms">
      <div>
        <h1>GET IN TOUCH</h1>
        <form action="#">
          <div className="GetInTouchForms_InputBox_Query_all">
            <div className="GetInTouchForms_InputBox_Query">
              <input type="text" placeholder="Enter your name" required />
            </div>
            <div className="GetInTouchForms_InputBox_Query">
              <input type="email" placeholder="Your Email" required />
            </div>
            <div className="GetInTouchForms_InputBox_Query">
              <input
                type="tel"
                placeholder="Enter your phone number"
                required
              />
            </div>
            <div className="GetInTouchForms_InputBox_Query">
              <input type="text" placeholder="Your Subject" required />
            </div>
          </div>
          <div className="GetInTouchForms_InputBox">
            <textarea type="textarea" placeholder="Message" />
          </div>
          <div className="GetInTouchFomrs_Agree">
            <input type="checkbox" />
            <p>I agree that my submitted data is being collected and stored.</p>
          </div>
          <div className="button">
            <input type="submit" value="SUBMIT" />
          </div>
        </form>
      </div>
    </div>
  );
}

export default GetInTouchForms;
