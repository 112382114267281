import React from 'react';
import './QuotePoliciesItem.css';

/** 
 * OtherBranchesItem Component
 * This component is basically for displaying each contact item inside the OtherBranches component
 * @param props.title - the name of each product
 * @param props.text - Primary text component for each product
 * @param props.text1 - secondary text component for each product
 * @param props.src - the icon to represent each product
 * @returns a JSX element for branches
 */

function QuotePoliciesItem({title, text, text1, src}) {
  return (
    <div className='QuotePoliciesItem'>
        <img src={src} />
        <h4>{title}</h4>
        <p>{text}</p>
        <p>{text1}</p>
        <hr/>
    </div>
  )}

export default QuotePoliciesItem;
