import React from "react";
import "./products.css";
import QuotePolicies from "../components/QuotePolicies";
import ProductHighlight from "../components/ProductHighlight";
import ProductsItem from "../components/ProductsItem";

/**
 * Product Page
 * This page is dedicated to enlisting and detailing all the Insurance products
 * @returns a JSX element containing components to make a complete page
 */

function Products() {
  return (
    <div className="Products_Page">
      <div className="Products_Highlight">
        <div>
          <h1>PRODUCTS</h1>
          {/* <p>Lorem ipsum dolor sit amet, consectetur <br /> adipiscing  elit, sed do eiusmod tempor <br /> incididunt ut labore et dolore magna aliqua.</p> */}
        </div>
      </div>
      <div className="Products_Page_Highlight">
        <p>
          We attach great importance to offering excellent total customer
          service to our <br /> valued clients and meeting all claims
          obligations promptly.
        </p>
        <QuotePolicies />
      </div>
      <div className="Products_Page_Selected">
        <ProductHighlight />
      </div>
      <div className="Products_Page_Others">
        <div>
          <h1>Other Insurance Coverages</h1>
        </div>
        <div className="Products_Page_Policies">
          {/* <div>
            <ProductsItem
              text="Aviation Insurance"
              productLink={"http://localhost:3000/forms"}
            />
          </div> */}
          <div>
            <ProductsItem
              text="Bonds Insurance"
              productLink={"http://localhost:3000/forms"}
            />
          </div>
          <div>
            <ProductsItem
              text="Marine Insurance"
              productLink={"http://localhost:3000/forms"}
            />
          </div>
          <div>
            <ProductsItem
              text="Home Insurance"
              productLink={"http://localhost:3000/forms"}
            />
          </div>
          {/* <div><ProductsItem text='Life Insurance' productLink={'http://localhost:3000/forms'}/></div> */}
          <div>
            <ProductsItem
              text="Contractors All Risk"
              productLink={"http://localhost:3000/forms"}
            />
          </div>
          <div>
            <ProductsItem
              text="Machinery Breakdown"
              productLink={"http://localhost:3000/forms"}
            />
          </div>
          <div>
            <ProductsItem
              text="Money Insurance"
              productLink={"http://localhost:3000/forms"}
            />
          </div>
          <div>
            <ProductsItem
              text="Erection All Risk"
              productLink={"http://localhost:3000/forms"}
            />
          </div>
          <div>
            <ProductsItem
              text="Goods In-Transit"
              productLink={"http://localhost:3000/forms"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;
