import React from "react";
import "./ProductHighlight.css";
import GeneralInsurance from "../media/img/pic.png";

function ProductHighlight() {
  return (
    <div className="ProductHighlight">
      <div className="ProductHighlight_Left">
        <img src={GeneralInsurance} width="531px" />
      </div>
      <div className="ProductHighlight_Right">
        <h1>
          PROTECTION FOR YOU <br /> AND YOUR PROPERTIES
        </h1>
        <p>
          We offer flexible third party and comprehensive <br /> motor insurance
          policies to meet your needs. <br /> No matter your choice, we've got
          you covered.
        </p>
        <div className="ProductHighlight_Right_Bullet">
          <div>
            <div className="ProductHighlight_Right_Bullet_Icon"></div>
            <p>Third-Party Policy</p>
          </div>
          <div>
            <div className="ProductHighlight_Right_Bullet_Icon"></div>
            <p>comprehensive Policy</p>
          </div>
          <div>
            <div className="ProductHighlight_Right_Bullet_Icon"></div>
            <p>Third Party Plus</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductHighlight;
