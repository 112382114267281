import React from 'react';
import './ProductsItem.css';

function ProductsItem({text, productLink}) {
  return (
    <div className='ProductsItem'>
        <div className='ProductsItem_Single'>
            <p><a href={productLink}>{text}</a></p> 
        </div>
    </div>
    )
}

export default ProductsItem;
