import React, { useState } from 'react';
import './quote.css';
import PersonalDetails from '../components/PersonalDetails';
import QuotePoliciesItem from '../components/QuotePoliciesItem';
import Car from '../media/img/Car.svg';
import Fire from '../media/img/fire.svg';
import Plane from '../media/img/aeroplane.svg';
import Injury from '../media/img/injury.png';
import QuotePoliciesAction from '../components/QuotePoliciesAction';
import GetAQuoteTravel from '../components/GetAQuoteTravel';
import GetAQuoteInjury from '../components/GetAQuoteInjury';
import GetAQuoteMotor from '../components/GetAQuoteMotor';

/** 
 * Quote Page
 * This page is purposefuly for purchasing and making claims which is linked to the Get a quote link
 * @returns a JSX element containing components to make a complete page
 */

function Quote() {

    const [value, setValue] = React.useState('1');
        const handleChange = (event, newValue) => {setValue(newValue);};

        const [toggleState, setToggleState] = useState(1);
        const toggleTab = (index) => {
            setToggleState(index)
        }

    return (
        <div className='Quote'>
                <div className='Quote_Highlight'>
                    <div>
                    <h1>GET A QUOTE</h1>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur <br /> adipiscing  elit, sed do eiusmod tempor <br /> incididunt ut labore et dolore magna aliqua.</p> */}
                    </div>
                </div>
                <div>
                    <div className='QuotePolicies_Cards bloc-tabs'>
                        <div className={toggleState === 1 ? "tabs active-tab hightlight" : "tabs"} onClick={() => toggleTab(1)} style={{cursor : 'pointer'}}>
                            <QuotePoliciesItem title="Motor Insurance" text="This covers your vehicles" text1=" from potential risks..." src={Car} />
                        </div>
                        <div className={toggleState === 2 ? "tabs active-tab hightlight" : "tabs"} onClick={() => toggleTab(2)} style={{cursor : 'pointer'}}>
                            <QuotePoliciesItem title="Fire &amp; Allied Perils" text="This covers any unforeseen" text1=" and sudden physical loss ..." src={Fire} />
                        </div>
                        <div className={toggleState === 3 ? "tabs active-tab hightlight" : "tabs"} onClick={() => toggleTab(3)} style={{cursor : 'pointer'}}>
                            <QuotePoliciesItem title="Travel Insurance" text="This covers accidental" text1=" injuries  or death and ..." src={Plane} />
                        </div>
                        <div className={toggleState === 4 ? "tabs active-tab hightlight" : "tabs"} onClick={() => toggleTab(4)} style={{cursor : 'pointer'}}>
                            <QuotePoliciesItem title="Accidents" text="This provides compensation" text1=" for death, injuries..." src={Injury} style={{backgroundColor : "#96C947"}} />
                        </div>
                    </div>
                    <div className='content-tabs'>
                        <div className={toggleState === 1 ? "content active-content" : "content"} style={{backgroundColor : ''}}>
                        {/*    <QuotePoliciesAction /> */}
                            <div className='Quote_Bar'>
                                <hr />
                                <GetAQuoteMotor />
                            </div>
                        </div>
                        <div className={toggleState === 2 ? "content active-content" : "content"} style={{backgroundColor : ''}}>
                        {/*    <QuotePoliciesAction /> */}
                            <div className='Quote_Bar'>
                                <hr />
                                <PersonalDetails />
                            </div>
                        </div>
                        <div className={toggleState === 3 ? "content active-content" : "content"} style={{backgroundColor : ''}}>
                         {/*   <QuotePoliciesAction /> */}
                            <div className='Quote_Bar'>
                                <hr />
                                <GetAQuoteTravel />
                            </div>
                        </div>
                        <div className={toggleState === 4 ? "content active-content" : "content"} style={{backgroundColor : ''}}>
                        {/*    <QuotePoliciesAction /> */}
                            <div className='Quote_Bar'>
                                <hr />
                                <GetAQuoteInjury />
                            </div>
                        </div>
                    </div>
                </div>
                <div>

                </div>
                
        </div>
    )
}

export default Quote;