import React from 'react';
import './Navbar.css';
import { Nav, NavLink, NavMenu, NavBtn, NavBtnLink, NavLogo, Bars} from './NavbarElements';
//import { Nav, NavbarContainer, NavMenu, NavItem, NavLinks, NavLogo, Bars, NavBtn, NavBtnLink } from './NavbarElements';

/** 
 * Navbar Component
 * This is dedicated to the nav links for navigation between the pages
 * Path to all pages has been clearly detailed below
 * @returns a JSX element for branches
 */

function Navbar({toggle}) {
    return (
        <>



        {/* <Nav>
            <NavbarContainer>
                <NavLogo to='/' className='Logo_Space'>
                    <img src={require('../../media/img/HomepageHeaderLogo.png')} width='150px'/>
                </NavLogo>
                <Bars onClick={toggle} />
                <NavMenu>
                    <NavItem>
                        {/* <NavLinks to="/about">About</NavLinks> */} {/*
                        <NavLinks to="/products" activeStyle>
                            Our Products
                        </NavLinks>
                        <NavLinks to="/about" activeStyle>
                            About Us
                        </NavLinks>
                    </NavItem>
                </NavMenu>
                <NavBtn>
                    <NavBtnLink to="/quote">
                        Get A QUOTE
                    </NavBtnLink>
                </NavBtn>
            </NavbarContainer>
        </Nav> */}











        <Nav className='NavbarItems'>
            <NavLink to="/" className='Logo_Space'>
                <img src={require('../../media/img/HomepageHeaderLogo.png')} width='150px'/>
            </NavLink>
            <Bars onClick={toggle} />
            <NavMenu>
                <NavLink to="/" activeStyle>
                    Home
                </NavLink>
                <NavLink to="/about" activeStyle>
                    About Us
                </NavLink>
                <NavLink to="/products" activeStyle>
                    Our Products
                </NavLink>
                <NavLink to="/forms" activeStyle>
                    Forms
                </NavLink>
                <NavLink to="/contact" activeStyle>
                    Contact Us
                </NavLink>
                <NavBtnLink to="/quote"> Get A QUOTE </NavBtnLink>
            </NavMenu>
            {/* <NavBtn>
                <NavBtnLink to="/Get A QUOTE">
                    Get A QUOTE
                </NavBtnLink>
            </NavBtn> */}
        </Nav>
        </>
    )
}

export default Navbar
