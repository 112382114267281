import React from "react";
import "./Management.css";
import ManagementItem from "./ManagementItem";
import Dawda from "../media/img/Staff/Dawda3.jpg";
import Rougie from "../media/img/Staff/Rougie2.jpg";
import Amat from "../media/img/Staff/Amat2.jpg";
import Nana from "../media/img/Staff/Nana2.jpg";
import Awa from "../media/img/Staff/Awa.jpg";
import Bah from "../media/img/Staff/Bah.jpg";
import Manneh from "../media/img/Staff/manneh.jpg";
import Ebrahima from "../media/img/Staff/Ebrahima.jpg";
import Nyass from "../media/img/Staff/Nyass.jpg";
import Kalilu from "../media/img/Staff/Kalilu.jpg";

function Management() {
  return (
    <div className="Management">
      <div>
        <div className="Management_Top">
          <h1>Management</h1>
        </div>
        <div className="Management_Down">
          <ManagementItem
            imageLink={Dawda}
            staff="Mr. Dawda Sarge"
            Position1="Managing Director"
            Position2="Chief Executive Officer"
          />
          <ManagementItem
            imageLink={Rougie}
            staff="Mrs. Rougie Gaye Nyan"
            Position1="Senior Manager"
            Position2="Technical-Reinsurance & Marketing"
          />
          <ManagementItem
            imageLink={Amat}
            staff="Mr. Amat Bittaye"
            Position1="Accounts"
            Position2="Finance & Systems"
          />
          <ManagementItem
            imageLink={Nana}
            staff="Mrs. Nana Njie Samba"
            Position1="Manager"
            Position2="Administration & Corporate Affairs"
          />
          {/* <ManagementItem imageLink={} staff='' Position1='' Position2='' /> */}
        </div>
        <div className="Management_Top" style={{ marginTop: "50px" }}>
          <h1>Technical Team</h1>
        </div>
        <div className="Management_Down">
          <ManagementItem
            imageLink={Kalilu}
            staff="Mr. Ebrahima Samba"
            Position1="Asistant Manager"
            Position2="Technical"
          />
          <ManagementItem
            imageLink={Nyass}
            staff="Mr. Ebrima Nyass"
            Position1="Assistant Manager"
            Position2="Technical"
          />
          <ManagementItem
            imageLink={Manneh}
            staff="Mr. Saibeh Manneh"
            Position1="Senior"
            Position2="Underwriting Officer"
          />
          <ManagementItem
            imageLink={Awa}
            staff="Mrs Awa Fatty Njie"
            Position1="Senior"
            Position2="Underwriting Officer"
          />
          <ManagementItem
            imageLink={Ebrahima}
            staff="Mr. Kalilu Bah"
            Position1="Senior"
            Position2="Underwriting Officer"
          />
          <ManagementItem
            imageLink={Bah}
            staff="Mr. Bah"
            Position1="Manager"
            Position2="IT Officer"
          />
        </div>
      </div>
    </div>
  );
}

export default Management;
