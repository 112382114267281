import React from "react";
import InsuranceProduct from "../../components/InsuranceProduct";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import MotorInsuranceImage from "../../media/img/aboutUsPic2.png";
import "./MotorInsurance.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InsuranceProductImage from "../../media/img/insuranceItem.png";

/**
 * Product Page
 * This page is dedicated to enlisting and detailing all the Insurance products
 * @returns a JSX element containing components to make a complete page
 */

function MotorInsurance() {
  return (
    <div className="productsPage">
      <div className="Products_Highlight">
        <div>
          <h1>Motor Insurance</h1>
          {/* <p>Lorem ipsum dolor sit amet, consectetur <br /> adipiscing  elit, sed do eiusmod tempor <br /> incididunt ut labore et dolore magna aliqua.</p> */}
        </div>
      </div>
      <div className="productsPageItem">
        <InsuranceProduct
          ProductName="Motor Insurance"
          Paragraph1="Motor Insurance is a type of insurance policy which covers your vehicles from potential risks financially. Policyholder's car or two wheeler is provided financial security against damages arising out of accidents and other threats. In Gambia, motor insurance is mandatory."
          Paragraph2="You can get all your private Motor Insurance from us whether it is third party only cover, third party, fire and theft cover, comprehensive or enhanced comprehensive called the Executive motor insurance cover."
          InsuranceProductImage={InsuranceProductImage}
        />
      </div>
      <div className="productsPageExplanation">
        <h1>What does motor insurance cover?</h1>
        <p>
          Prime Insurance Gambia offers world-class service, prompt claims
          settlement and flexible motor insurance policies that are tailored to
          meet your insurance needs. Let's talk about your car insurance.
          <br /> <br />
          Choose the right insurance package for your car. We offer flexible
          third party and comprehensive motor insurance policies to meet your
          needs. No matter your choice, we've got you covered.
        </p>
        <div className="productsPageBenefitsList">
          <div>
            <CheckBoxIcon
              small="medium"
              style={{ color: "#175C2E", marginRight: "15px" }}
            />
            <p>Damage to third party property</p>
          </div>
          <div>
            <CheckBoxIcon
              small="medium"
              style={{ color: "#175C2E", marginRight: "15px" }}
            />
            <p>Personal accident cover</p>
          </div>
          <div>
            <CheckBoxIcon
              small="medium"
              style={{ color: "#175C2E", marginRight: "15px" }}
            />
            <p>Theft to your vehicle</p>
          </div>
          <div>
            <CheckBoxIcon
              small="medium"
              style={{ color: "#175C2E", marginRight: "15px" }}
            />
            <p>Damage to your own car</p>
          </div>
          <div>
            <CheckBoxIcon
              small="medium"
              style={{ color: "#175C2E", marginRight: "15px" }}
            />
            <p>Accidental cost</p>
          </div>
          <div>
            <CheckBoxIcon
              small="medium"
              style={{ color: "#175C2E", marginRight: "15px" }}
            />
            <p>Cover when you travel</p>
          </div>
        </div>
      </div>
      <div className="productsPageMoreDownload">
        <div className="productsPageMoreDownloadLeft">
          <div className="productsPageMoreDownloadLeftTop">
            <h3>Forms Download</h3>
          </div>
          <div className="productsPageMoreDownloadLeftMiddle">
            <div className="productsPageMoreDownloadLeftMiddleDownloadFile">
              <div className="productsPageMoreDownloadLeftMiddleDownloadFileIcon">
                <InsertDriveFileIcon style={{ color: "#fff" }} />
              </div>
              <div className="productsPageMoreDownloadLeftMiddleDownloadFileName">
                <p>Title.pdf</p>
              </div>
            </div>
            <div className="productsPageMoreDownloadLeftMiddleDownloadFile">
              <div className="productsPageMoreDownloadLeftMiddleDownloadFileIcon">
                <InsertDriveFileIcon style={{ color: "#fff" }} />
              </div>
              <div className="productsPageMoreDownloadLeftMiddleDownloadFileName">
                <p>Title.docx</p>
              </div>
            </div>
          </div>
          <div
            className="productsPageMoreDownloadLeftBottom"
            style={{
              backgroundImage: `url(${MotorInsuranceImage})`,
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          ></div>
        </div>
        <div className="productsPageMoreDownloadCenter">
          <div className="productsPageMoreDownloadMiddleTop">
            <h3>FAQ</h3>
          </div>
          <div style={{ padding: "20px" }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>How does the plan work?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Accidents or natural disasters</Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Are there any age limitations</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Yes. These are the ages applicable at inception and vary for
                  the different categories of lives. They are as follows:
                  {/* <ol>
                                    <li>Insured 18 - 59 years</li>
                                    <li>Spouse 18 - 59 years</li>
                                    <li>Children 0 - 21</li>
                                    <li>Extended Family 1 - 74 years</li>
                                </ol> */}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography>How do I make a claim</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  With the right documents and proof of accident.
                  {/* <ol>
                                        <li>Insured 18 - 59 years</li>
                                        <li>Spouse 18 - 59 years</li>
                                        <li>Children 0 - 21</li>
                                        <li>Extended Family 1 - 74 years</li>
                                    </ol> */}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <div className="productsPageMoreDownloadRight">
          {/* <GetAQuoteMotor style={{margin: '0px 0px'}} />  */}
          <div className="productsPageMoreDownloadRightTop">
            <h3>Claims Settlement</h3>
          </div>
          <div className="productsPageMoreDownloadRightMiddle">
            <div>
              <p>
                A very seamless claim process with some benefit payable within
                24hours, subject to submission of relevant documents and a
                completed claim form.
              </p>
            </div>
          </div>
          <hr style={{ height: "0.2px", border: "0.2px solid #d5d5d5" }} />
          <div style={{ padding: "15px 15px 15px 30px" }}>
            <h3 style={{ marginBottom: "15px" }}>How it works</h3>
            <p>
              <ul>
                <li>
                  Policyholder has flexibility in determining which covers and
                  riders are relevant
                </li>
                <li>
                  Policy can be structured on an accelerated or stand-alone
                  basis
                </li>
                <li>Benefits are paid out as lump sum</li>
                <li>
                  Policy application will be underwritten on a medical,
                  financial, and occupational basis depending on the sum
                  insured.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MotorInsurance;
