import React from "react";
import FormsDownloadLink from "../components/FormsDownloadLink";
import FormsHeading from "../components/FormsHeading";
import "./forms.css";

/**
 * Forms Page
 * This page is purposefly for downloading all forms data
 * @returns a JSX element containing components to make a complete page
 */

function Forms() {
  return (
    <div className="Forms">
      <div className="Forms_Highlight">
        <div>
          <h1>FORMS DOWNLOAD</h1>
          {/* <p>Lorem ipsum dolor sit amet, consectetur <br /> adipiscing  elit, sed do eiusmod tempor <br /> incididunt ut labore et dolore magna aliqua.</p> */}
        </div>
      </div>
      <div className="Forms_Downloads">
        <div>
          <div>
            <FormsHeading text="Proposal Forms" />
          </div>
          <div className="Forms_DownloadLinks">
            <FormsDownloadLink text="Motor Insurance" />
            <FormsDownloadLink text="Fire Insurance" />
            <FormsDownloadLink text="Goods-In-Transit" />
            <FormsDownloadLink text="Travel Insurance" />
            <FormsDownloadLink text="Aviation Insurance" />
            <FormsDownloadLink text="Marine Insurance" />
            <FormsDownloadLink text="Machinery Insurance" />
            <FormsDownloadLink text="Contractors all risk" />
            <FormsDownloadLink text="Burglary Insurance" />
          </div>
        </div>
        <div>
          <div>
            <div>
              <FormsHeading text="Claim Forms" />
            </div>
            <div className="Forms_DownloadLinks">
              <FormsDownloadLink text="Motor Insurance" />
              <FormsDownloadLink text="Fire Insurance" />
              <FormsDownloadLink text="Goods-In-Transit" />
              <FormsDownloadLink text="Travel Insurance" />
              <FormsDownloadLink text="Aviation Insurance" />
              <FormsDownloadLink text="Marine Insurance" />
              <FormsDownloadLink text="Machinery Insurance" />
              <FormsDownloadLink text="Contractors all risk" />
              <FormsDownloadLink text="Burglary Insurance" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Forms;
