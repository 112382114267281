import React from 'react';
import './FirstChoice.css';

/** 
 * FirstChoice Component
 * This component highlights the key message of Prime Gambia
 * This section is divided into 3 blocks
 * Frist block : Slim bar running accross to the left, which is hidden on desktop view
 * Second block : Actual Content for highlight which is visible on desktop
 * Third block : Slim bar running accross to the right
 * @returns a JSX Element
 */

function FirstChoice({Title}) {
    return (
        <div className='FirstChoice'>
            <div className='First_Choice_Highlight'>
                <div className='First_Choice_Highlight_Thread_Left'>
                    <hr /> {/* Slim bar running accross to the left, which is hidden on desktop view */}
                </div>
                <div className='First_Choice_Highlight_Main'>
                    <h1>{Title}</h1> {/* Actual Content for highlight */}
                </div>
                <div className='First_Choice_Highlight_Thread'>
                    <hr /> {/* Slim bar running accross to the right */}
                </div>
            </div>
        </div>
    )
}

export default FirstChoice
