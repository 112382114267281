import React from 'react';
import './ManagementItem.css';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import TwitterIcon from '@mui/icons-material/Twitter';

function ManagementItem({staff, imageLink, Position1, Position2}) {
  return (
    <div className='ManagementItem'>
        <div className='ManagementItem_Top'>
            {/* <img src={imageLink} cover /> */}
            <div  style={{width: '100%', height: '100%', backgroundImage: `url(${imageLink})`, backgroundPosition: 'top center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>

            </div>
        </div>
        <div className='ManagementItem_Down'>
            <h4><b>{staff}</b></h4>
            <p>{Position1} / <br/> {Position2}</p>
            <div className='ManagementItem_Down_Socials'>
                <a href=''><InstagramIcon fontSize="small" /></a>
                <a href=''><EmailIcon fontSize="small" style={{margin: '0px 8px'}} /></a>
                <a href=''><TwitterIcon fontSize="small" /></a>
            </div>
        </div>
    </div>
    )
}

export default ManagementItem;
