import React from "react";
import "./OtherBranches.css";
import OtherBranchesItem from "./OtherBranchesItem";

/**
 * Other Branches Component
 * This component is a section provivided for the display of contacts of the remaining branches of Prime
 * @returns a JSX element that renders a <OtherBranchesItem /> component
 */

function OtherBranches() {
  return (
    <div className="OtherBranches_Section">
      <h1>Our Network</h1>
      {/* Components for each other branch */}
      <div>
        <div>
          <OtherBranchesItem
            text="Soma, Trans Gambia Highway"
            text1="Tel: (00220) 3813874/6307 040"
            title="SOMA BRANCH"
          />
        </div>
        <div>
          <OtherBranchesItem
            text="Serrekunda, 8 Sayerr Jobe Avenue"
            text1="Tel: (00220) 4377 744/3813 884"
            title="SERREKUNDA BRANCH"
          />
        </div>
        <div>
          <OtherBranchesItem
            text="Barra, Behind Barra Police Station"
            text1="Tel: (00220) 3813 880/3813 879"
            title="BARRA BRANCH"
          />
        </div>
        <div>
          <OtherBranchesItem
            text="0803 Basse Santa Su"
            text1="Tel: (00220) 3813 882/3813 876"
            title="BASSE BRANCH"
          />
        </div>
      </div>
    </div>
  );
}

export default OtherBranches;
