import React from 'react';
import './MainContactInfoItem.css';

/** 
 * Main Contact Information Item Component
 * This component serves as the center piece for the homepage that rotates multiple animations to represent the services of the brand
 * This section is divided into 2 blocks
 * @param props.title - the Title of the component
 * @param props.action - the call to action text required of a visitor
 * @param props.Icon - the icon that represent each component
 * @param props.text - the phrase to represent each component 
 * @param props.text1 - the second provision for each card representation
 * @returns a JSX element that displays a single contact info about Prime Gambia, which is exported to another div
 */

function MainContactInfoItem({ text, text1, Icon, title, action}) {
    return (
        <div className='MainContactInfoItem_Comp'>
            <div>
                <h2>{title}</h2>                
            </div>
            <div className='MainContactInfoItem_Details'>
                <p>{text}</p>
                <p>{text1}</p>
            </div>
            <div className='MainContactInfoItem_highlight'>
                <Icon style={{fontSize : '30px', margin: '0px 15px'}} />
                <h2>{action}</h2>
            </div>
        </div>
    )
}

export default MainContactInfoItem
