import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import './QuotePoliciesAction.css';
import { Cancel } from '@material-ui/icons';

/** 
 * Quote Policies Action Component
 * This component displays actions that can be taken upon selecting a policy on the homepage
 * @returns a JSX element for the homepage
 */

function QuotePoliciesAction({title, content, benefits}) {

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
  };

  const [open, setOpen] = React.useState(false);
  const [opeen, setOpeen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleOpeen = () => setOpeen(true);
  const handleClose = () => setOpen(false);
  const handleeClose = () => setOpeen(false);

  return (
  <div className='QuotePoliciesAction'>
      
  
    {/* Action Buttons */}
      <div>
          <Button onClick={handleOpen} style={{textDecoration: 'none', color: '#fff'}}><h3>GET QUOTE</h3></Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="modalViewQuote" sx={style}>
              <div style={{width: '100%', backgroundColor: '#175C2E', color: '#fff', display: 'flex', justifyContent: 'space-between', height: '14vh', padding: '0px 20px 0px 20px', alignItems: 'center'}}>
                <h1>{title}</h1>
                <Cancel onClick={handleClose} size="large" />
              </div>
              <div className="modalViewQuoteContent"> 
              {content}
              </div>
            </Box>
          </Modal>
      </div>
      <div>
        <Button onClick={handleOpeen} style={{textDecoration: 'none', color: '#fff'}}><h3>BENEFITS</h3></Button>
          <Modal
            open={opeen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="modalViewQuote" sx={style}>
              <div style={{width: '100%', backgroundColor: '#175C2E', color: '#fff', display: 'flex', justifyContent: 'space-between', height: '14vh', padding: '0px 20px 0px 20px', alignItems: 'center'}}>
                <h1>{title}</h1>
                <Cancel onClick={handleeClose} size="large" />
              </div>
              <div  className="modalViewQuoteContent">
              {benefits}
              </div>
            </Box>
          </Modal>
      </div>
      <div>
        <a href=''><h3>MAKE CLAIMS</h3></a>
      </div>
  </div>
  
  )
}

export default QuotePoliciesAction;
