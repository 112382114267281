import React from 'react';
import './GetAQuoteInjury.css';
import FormsHeading from './FormsHeading';

function GetAQuoteInjury() {
  return (
        <div className='GetAQuoteInjury'>
            <div>
            <FormsHeading text='Personal  Details' />
            </div>
            <div className='GetAQuoteInjury_Forms'> 
                <form action='#'> 
                    <div className='GetAQuoteInjury_TopQuery'>
                        <div className='GetAQuoteInjury_InputBox_Query'>
                            <label for="fname">Fullname: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="text" placeholder="Please enter your fullname" required />
                        </div>
                        <div className='GetAQuoteInjury_InputBox_Query'>
                            <label for="fname">Phone: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="tel" placeholder="Enter your contact here" required />
                        </div>
                        <div className='GetAQuoteInjury_InputBox_Query'>
                            <label for="fname">Email Address: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="email" placeholder="Enter your email number" required />
                        </div>
                        <div className='GetAQuoteInjury_InputBox_Query'>
                            <label for="fname">Nature of Work: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="text" placeholder="Select Nature of Work" required name="example2" list="exampleList2"/>
                                <datalist id="exampleList2" style={{backgroundColor: 'green'}}>
                                    <option value="Clerical" />  
                                    <option value="Non Clerical" />
                                </datalist>
                        </div>
                    </div>
                    <FormsHeading text='Policy  Details' />
                    <div className='GetAQuoteInjury_MiddleQuery'>
                        <div className='GetAQuoteInjury_InputBox_Query'>
                            <label for="fname">Start Date: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="date" placeholder="Enter Departure Date" required />
                        </div>
                        <div className='GetAQuoteInjury_InputBox_Query'>
                            <label for="fname">End Date: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="date" placeholder="Enter Return Date" required />
                        </div>
                        <div className='GetAQuoteInjury_InputBox_Query'>
                            <label for="fname">Sum Insured: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="text" placeholder="Enter Insurance Amount" required />
                        </div>
                        <div className='GetAQuoteInjury_InputBox_Query'>
                            <label for="fname">Currency: <span style={{color : 'red'}}>*</span></label><br/>
                            <input type="text" placeholder="Select Currency" required name="example" list="exampleList"/>
                                <datalist id="exampleList" style={{backgroundColor: 'green'}}>
                                    <option value="GMD" />  
                                    <option value="USD" />
                                    <option value="GHC" /> 
                                    <option value="GBP" /> 
                                </datalist>
                        </div>
                    </div>
                    <div className="submitAction" >
                        <input className='FormSubmit' type="submit" />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default GetAQuoteInjury;
