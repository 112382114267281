import { Button } from "@mui/material";
import React from "react";
import Lottie from "react-lottie";
import animationData from "../media/img/passcode.json";
import customerSuport from "../media/img/customerSupport.json";
import protection from "../media/img/protection.json";
import trust from "../media/img/trust.json";
import { Link } from "react-router-dom";
import "./ChooseUs.css";

function ChooseUs() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const customerSupport = {
    loop: true,
    autoplay: true,
    animationData: customerSuport,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const protecTion = {
    loop: true,
    autoplay: true,
    animationData: protection,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const Trust = {
    loop: true,
    autoplay: true,
    animationData: trust,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="chooseUs">
      <div className="chooseUsContainer">
        <div className="chooseUsContainerLeft">
          <h5>Why People Choose Us?</h5>
          <h1
            style={{ fontSize: "50px", fontWeight: "400", margin: "30px 0px" }}
          >
            We're here for the good times. The bad times too.
          </h1>
          <Link to="/contact">
            <Button
              variant="contained"
              style={{ width: "150px", backgroundColor: "#175C2E" }}
            >
              Learn More
            </Button>
          </Link>
        </div>
        <div className="chooseUsContainerRight">
          <div className="chooseUsContainerTop">
            <div>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "60px",
                    height: "60px",
                    marginRight: "20px",
                  }}
                >
                  <Lottie options={customerSupport} height={60} width={60} />
                </div>
                <div>
                  <h4>24/7 Customer support</h4>
                </div>
              </div>
              <div>
                <p>
                  Insurance Policies provide protection to all your insurance
                  needs.
                </p>
              </div>
            </div>
            <div>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "60px",
                    height: "60px",
                    marginRight: "20px",
                  }}
                >
                  <Lottie options={protecTion} height={70} width={70} />
                </div>
                <div>
                  <h5>Constant protection</h5>
                </div>
              </div>
              <div>
                <p>
                  Insurance policies provide protection to your life and based
                  on the differences in policies it also gives you the
                  opportunity to save money as well.
                </p>
              </div>
            </div>
          </div>
          <div className="chooseUsContainerBottom">
            <div>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "60px",
                    height: "60px",
                    marginRight: "20px",
                  }}
                >
                  <Lottie options={defaultOptions} height={60} width={60} />
                </div>
                <div>
                  <h5>Uninterrupted service</h5>
                </div>
              </div>
              <div>
                <p>
                  Insurance policies provide protection to your life and based
                  on the differences in policies it also gives you the
                  opportunity to save money as well.
                </p>
              </div>
            </div>
            <div>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "20px",
                  }}
                >
                  <Lottie options={Trust} height={60} width={60} />
                </div>
                <div>
                  <h5>Trusted since 1999</h5>
                </div>
              </div>
              <div>
                <p>
                  Insurance policies provide protection to your life and based
                  on the differences in policies it also gives you the
                  opportunity to save money as well.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChooseUs;
