import React from 'react';
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink, SidebarRoute, SideBtnWrap } from './SidebarElements';
import FacebookIcon from '@material-ui/icons/Facebook'; //FB Icon
import TwitterIcon from '@material-ui/icons/Twitter'; //Twitter Icon
import InstagramIcon from '@material-ui/icons/Instagram'; //Intagram Icon
import LinkedInIcon from '@material-ui/icons/LinkedIn'; //LinkedIn Icon

const Sidebar = ({isOpen, toggle}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        {/* <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', position: 'relative'}}> */}
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>

            <SidebarMenu>
                <SidebarLink to="/">
                    Home
                </SidebarLink>
            </SidebarMenu>
            <SidebarMenu>
                <SidebarLink to="/about">
                    About Us
                </SidebarLink> 
            </SidebarMenu>
            <SidebarMenu>
                <SidebarLink to="/products">
                    Our Products
                </SidebarLink> 
            </SidebarMenu>
            <SidebarMenu>
                <SidebarLink to="/forms">
                    Forms
                </SidebarLink> 
            </SidebarMenu>
            <SidebarMenu>
                <SidebarLink to="/contact">
                    Contact Us
                </SidebarLink> 
            </SidebarMenu>
            <SideBtnWrap>
                <SidebarRoute to="/quote">
                    Get A Quote
                </SidebarRoute> 
            </SideBtnWrap>

        </SidebarWrapper>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#175C2E', color: '#fff', width: '100%', height: '30%', padding: '30px 20px'}}>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center'}}>
                <p>Prime Insurance is a one-stop
                    financial institution in General
                    Insurance business. 
                </p>
                <div style={{display: 'flex', justifyContent: 'center', width: '100%'}} className='footer_Upper_Section_Col4_Socials'>
                        <div>
                            <a href=""><FacebookIcon /></a>
                        </div>
                        <div>
                            <a href=""><TwitterIcon /></a>
                        </div>
                        <div>
                            <a href=""><InstagramIcon /></a>
                        </div>
                        <div>
                            <a href=""><LinkedInIcon /></a>
                        </div>
                </div>
                </div>
            </div>
        {/* </div> */}
    </SidebarContainer>
  )
}

export default Sidebar;